@import "src/styles/_variables-mixins.scss";
.select {
  width: 100%;
  max-width: 300px;
  position: relative;

  @include mediaQuery($md) {
    & div + div {
      position: relative !important;
    }
  }

  &__content {
    position: relative;
    z-index: 89;

    width: 100%;
    max-height: 300px;
    margin-top: 6px;

    padding: 0;

    overflow-y: auto;
    background-color: var(--mobility-color-light-50);
    border-radius: 8px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
  }

  &__item-label {
    position: relative;
    display: flex;
    align-items: center;

    min-height: 25px;
    padding: 8px 12px;
    user-select: none;

    background-color: var(--mobility-color-dark-50);
    color: var(--mobility-color-orange-700);
    font-family: var(--font-outfit);
    font-size: 14px;
    font-weight: 500;

    white-space: normal;
    overflow: visible;
  }

  &__item {
    position: relative;
    display: flex;
    align-items: center;
    gap: 6px;

    min-height: 25px;
    padding: 8px 16px;
    user-select: none;

    color: var(--mobility-color-dark-700);
    font-family: var(--font-outfit);
    font-size: 14px;
    font-weight: 400;

    &[data-state='checked'] {
      background-color: var(--mobility-color-orange-100);
    }

    &[data-disabled] {
      color: var(--mauve-8);
      pointer-events: none;
    }

    &:hover {
      outline: none;
      background-color: var(--mobility-color-orange-100);
    }

    &[data-highlighted] {
      outline: none;
      background-color: var(--mobility-color-orange-100);
    }

    white-space: normal;
    overflow: visible;
    word-break: break-word;
  }

  &__item-indicator {
    position: absolute;
    left: 0;
    width: 25px;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    & > span {
      font-size: 18px;
    }
  }
}

.popup {
  position: absolute;
  z-index: 1000;

  margin-top: 6px;

  width: 100%;
  max-height: 300px;

  padding: 0;

  overflow-y: auto;
  background-color: var(--mobility-color-light-50);
  border-radius: 8px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
}

.popup::-webkit-scrollbar {
  display: none;
}

//   .suggestion {
//     display: inline-block;
//     padding: 8px 16px;
//     cursor: pointer;

//     &:hover {
//       background-color: var(--mobility-color-orange-100);
//     }
//   }
